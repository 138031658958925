.enrollment-section {
  max-width: 1200px;
  margin: 0 auto;

  &__header {
    padding-bottom: 40px;

    @media #{$col-md} {
      padding-bottom: 280px;
    }

    &--blue {
      background-color: $blue;
    }

    &--blue2 {
      background-color: $blue2;
    }

    &--black {
      background-color: $black;
    }
  }

  &__form {
    max-width: 997px;
    text-align: center;
    background-color: $white;
    border: 1px solid rgba(47, 57, 71, 0.05);
    border-radius: 6px;
    padding: 40px 20px 40px 20px;
    margin: 40px 20px 0 20px;

    @media #{$col-md} {
      margin: 0 auto;
      padding: 100px 200px 80px 200px;
      margin-top: -290px;
    }

    box-shadow: 0 12px 16px #416de005;
  }

  &__paragraph {
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
    margin: 0 auto;
    margin-bottom: 60px;
  }

  &__privacy-info {
    opacity: 0.6;
    font-size: 12px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    margin-top: 20px;
    line-height: 18px;
  }
}
