$font-default: 'Poppins', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$font-poppins-semi-bold: 'Poppins-SemiBold', sans-serif;
$font-lato: 'Lato', sans-serif;
$font-proximaNova: 'Proxima Nova', sans-serif;

$nav-width: 300px;
$nav-width-mobile: 280px;

$header-height-mobile: 56px;
$header-height: 56px;

$grid-gutter-default: 20px;
$grid-gutter-lg: 20px;
$grid-gutter-xl: 20px;

$grid-width-default: 960px;
$grid-width-lg: 1080px;
$grid-width-xl: 1200px;

$column-width-default: ($grid-width-default - ($grid-gutter-default * 11)) / 12;
$column-width-lg: ($grid-width-lg - ($grid-gutter-lg * 11)) / 12;
$column-width-xl: ($grid-width-xl - ($grid-gutter-xl * 11)) / 12;
