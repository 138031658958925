.get-certified-section {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 40px 40px 40px;

  @media #{$col-md} {
    padding: 100px 0;
  }

  & .button {
    width: 140px;
    background-color: #126ee6;
    color: $white;
    text-decoration: none;
    padding: 12px 16px;
    border-radius: 3px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0.21px;
    text-align: center;
    line-height: 24px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__tag {
    font-size: 11px;
    color: $black;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  &__paragraph {
    opacity: 0.6;
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
    padding: 20px 0;
    max-width: 100%;
    margin: 0 auto;

    @media #{$col-md} {
      padding: 30px 0;
      max-width: 50%;
    }
  }
}
