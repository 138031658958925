.partnership {
  width: 100%;
  padding: 25px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    font-size: 11px;
    color: $black;
    letter-spacing: 2px;
    text-align: right;
    text-transform: uppercase;
    margin-right: 40px;
  }

  &__images {
    display: flex;

    :nth-child(2) {
      margin-left: 40px;
    }
  }
}
