.button-register {
  padding: 12px 16px;
  margin: 0 auto;
  background: $black;
  border-radius: 3px;
  border: 0;
  align-items: center;
  cursor: pointer;
  color: $white;
  font-size: 16px;
  letter-spacing: 0.21px;
  text-align: center;
  line-height: 24px;
  width: 138px;
  max-height: 48px;
  text-decoration: none;
  display: inline-block;

  &--background-light {
    background-color: $white;
    color: $blue;
    opacity: 0.9;
  }

  &--background-blue {
    background-color: $blue;
  }

  &--background-blue2 {
    background-color: $blue2;
  }

  &--background-black {
    background-color: $black;
  }

  &--medium {
    width: 140px;
  }

  &--color-white {
    color: $white;
  }

  &--color-blue {
    color: $blue;
  }

  &--color-blue2 {
    color: $blue2;
  }

  &--color-black {
    color: $black;
  }
}
