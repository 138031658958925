@import './../../../node_modules/tiny-slider/dist/tiny-slider.css';

.index-menu {
  padding: 20px;

  h1 {
    font-size: 26px;
    line-height: 56px;
  }

  li {
    margin-bottom: 6px;

    a {
      color: $blue;
    }
  }
}
