.career-section {
  padding: 40px 0;

  @media #{$col-md} {
    padding: 100px 0;
  }

  text-align: center;

  &--blue {
    background-color: $blue;
  }

  &--blue2 {
    background-color: $blue2;
  }

  &--black {
    background-color: $black;
  }

  & .button {
    width: 140px;
    background-color: $white;
    color: #126ee6;
    opacity: 0.9;
    text-decoration: none;
    padding: 12px 16px;
    border-radius: 3px;
    border: 0;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0.21px;
    text-align: center;
    line-height: 24px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__content {
    max-width: 500px;
    margin: 0 auto;
    padding: 0 20px;

    @media #{$col-md} {
      padding: 0;
    }
  }

  &__sub-heading {
    opacity: 0.5;
    font-size: 16px;
    color: $white;
    letter-spacing: 0;
    line-height: 24px;
    padding-bottom: 42px;
  }
}
