.logo-header {
  text-align: center;
  padding-top: 40px;

  &__header {
    text-align: center;
  }

  &__icon {
    margin-bottom: 14px;
  }

  &__heading {
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    line-height: 30px;
  }

  &__subHeading {
    opacity: 0.5;
    font-size: 12px;
    color: $black;
    letter-spacing: 0;
  }

  &__paragraph {
    opacity: 0.6;
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    line-height: 26px;
    padding: 0;

    @media #{$col-md} {
      padding: 10px 0;
    }
  }

  &--inverted {
    svg * {
      fill: $white;
    }

    .logo-header__heading {
      color: $white;
    }

    .logo-header__subHeading {
      color: $white;
      opacity: 0.5;
    }
  }
}
