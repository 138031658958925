.comment-item {
  display: inline-block;
  position: relative;

  &__content {
    min-height: 290px;
    background: $white;
    border: 1px solid rgba(47, 57, 71, 0.05);
    border-radius: 6px;
    padding: 40px;
  }

  &__bottom {
    bottom: 40px;
    position: absolute;
  }

  &__paragraph {
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    line-height: 26px;
  }

  &__author {
    font-size: 18px;
    color: $black;
    letter-spacing: 0;
    line-height: 28px;
  }

  &__location {
    opacity: 0.6;
    font-size: 13px;
    color: $black;
    letter-spacing: 0;
    line-height: 20px;
  }
}
