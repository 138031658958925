.top-header-section {
  display: flex;
  width: 100%;
  padding: 40px 20px 40px 20px;
  flex-direction: column-reverse;

  p:nth-child(2) {
    padding-top: 20px;
  }

  :nth-child(1) .heading {
    display: none;
  }

  @media #{$col-md} {
    flex-direction: row;
    padding: 100px 200px 100px 200px;

    p:nth-child(2) {
      padding-top: 0;
    }

    :nth-child(1) .heading {
      display: inline-flex;
    }

    :nth-child(2) .heading {
      display: none;
    }
  }

  &__column {
    width: 100%;

    @media #{$col-md} {
      width: 50%;
    }

    &:first-child {
      padding-right: 40px;
    }
  }

  &--blue {
    background-color: $blue;
  }

  &--blue2 {
    background-color: $blue2;
  }

  &--black {
    background-color: $black;
  }
}
