.landing-page-wrapper {
  background-color: $gray-light2;
}

.footer-logo {
  text-align: center;
  padding: 40px 0;

  @media #{$col-md} {
    padding: 100px 0;
  }
}
