@import './../../assets/scss/variables.scss';
@import './../../assets/scss/breakpoints.scss';

body {
  background: $background-color;
}

.wrapper {
  max-width: $grid-width-default;
  height: 100%;
  margin: 0 auto;

  @media #{$col-lg} {
    max-width: $grid-width-lg;
  }

  @media #{$col-xl} {
    max-width: $grid-width-xl;
  }
}

section {
  margin-bottom: 100px;
}
