.functionality-section {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;

  @media #{$col-md} {
    padding-top: 100px;
  }

  &__row {
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media #{$col-md} {
      padding-bottom: 95px;
      flex-direction: row;
    }
  }

  &__tiles {
    display: flex;
    flex-wrap: wrap;

    &-start {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;

      @media #{$col-md} {
        justify-content: flex-start;
        padding-bottom: 0;
      }
    }

    &-end {
      display: flex;
      justify-content: center;

      @media #{$col-md} {
        justify-content: flex-end;
      }
    }

    .tile {
      background: rgba(47, 57, 71, 0.02);
      border-radius: 6px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 10px 10px;

      @media #{$col-md} {
        width: 150px;
        height: 100px;
        margin: 10px 20px 10px 0;
      }
    }

    .tile-big {
      width: 40%;
      height: 200px;
      padding: 26px 30px;
      margin: 10px 10px;

      @media #{$col-md} {
        width: 234px;
        height: 165px;
        margin: 10px 0 10px 20px;
      }
    }

    .tile-text {
      opacity: 0.6;
      font-family: $font-proximaNova;
      font-size: 15px;
      color: $black;
      padding-top: 17px;
      text-align: center;
    }
  }

  &__text-box {
    max-width: 388px;
    padding-bottom: 40px;

    @media #{$col-md} {
      padding-bottom: 0;
    }

    .subtitle {
      opacity: 0.4;
      font-family: $font-poppins;
      font-size: 11px;
      color: $black;
      padding-bottom: 26px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .title {
      font-family: $font-proximaNova;
      font-size: 26px;
      color: $black;
      letter-spacing: 0;
      line-height: 40px;
      padding-bottom: 10px;
    }

    .text {
      opacity: 0.75;
      font-size: 15px;
      color: $black;
      letter-spacing: 0;
      line-height: 28px;
      padding-bottom: 20px;
    }

    .button {
      border-radius: 2px;
      font-family: $font-proximaNova;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0;
      padding: 6px 13.5px;
      text-align: center;
      line-height: 18px;
      border: 0;
      text-decoration: none;

      &--facebook {
        background: rgba(65, 109, 224, 0.1);
        color: #416de0;
      }

      &--unity {
        background: rgba(10, 11, 9, 0.1);
        color: #0a0b09;
      }

      &--tableau {
        background: rgba(69, 115, 135, 0.1);
        color: #457387;
      }
    }
  }
}

#img-slider {
  display: block;
}

.tns-nav {
  justify-content: center;
  width: 100%;
  display: flex;
  padding-top: 20px;

  button {
    border-radius: 100%;
    height: 8px;
    width: 8px;
    background: $white;
    padding: 0;
    border: 1px solid #126ee6;
    margin-right: 10px;

    &:focus {
      outline: 0;
    }
  }

  .tns-nav-active {
    background: #126ee6;
  }
}
