$base-gutter: 20px;
$bp-small: 768px;
$bp-medium: 992px;
$bp-large: 1200px;
$bp-xlarge: 1600px;

$colset: (
  1: percentage(1/12),
  2: percentage(2/12),
  3: percentage(3/12),
  4: percentage(4/12),
  5: percentage(5/12),
  6: percentage(6/12),
  7: percentage(7/12),
  8: percentage(8/12),
  9: percentage(9/12),
  10: percentage(10/12),
  11: percentage(11/12),
  12: percentage(12/12)
);

@mixin gridset($bp, $colset) {
  @each $colwidth, $width in $colset {
    .#{$bp}-#{$colwidth} {
      width: 100%;
      height: 100%; /* make position sticky work */
      max-width: $width;
      flex-basis: $width;
    }
    .#{$bp}-offset-#{$colwidth} { margin-left: $width; }
  }
}

.grid-row {
  > * {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;

    @media #{$col-md} {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (min-width: $bp-small) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: $bp-small) {
  @include gridset('sm', $colset);
}

@media (min-width: $bp-medium) {
  @include gridset('md', $colset);
}

@media (min-width: $bp-large) {
  @include gridset('lg', $colset);
}

@media (min-width: $bp-xlarge) {
  @include gridset('xl', $colset);
}

.grid-wrap {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;

  @media #{$col-xl} {
    min-width: 1200px;
  }
}

.hidden-sm {
  display: none;

  @media #{$col-sm} {
    display: flex;
  }
}
