.checkbox-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.checkbox-label {
  position: relative;
  cursor: pointer;
  font-size: 22px;
  background: #dbdcdd;
  border-radius: 5px;
  line-height: 20px;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  display: flex;
  flex: 1 0 auto;
}

.checkbox-custom {
  &__label {
    text-align: left;
    opacity: 0.6;
    font-size: 12px;
    color: $black;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
}

.checkbox-label input:checked ~ .checkbox-custom {
  transform: rotate(0deg) scale(1);
  opacity: 1;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0;
  width: 0;
  border-radius: 5px;
  border: solid $black;
  border-width: 0 3px 3px 0;
  transform: rotate(0deg) scale(0);
  opacity: 1;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid $black;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
