.form-submited {
  margin-top: 20px;
  background: $white;
  border-radius: 6px;
  padding: 50px 40px 40px 40px;
  text-align: center;

  @media #{$col-md} {
    margin: 0;
  }

  &__title {
    opacity: 0.6;
    font-weight: bold;
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 27px;
    margin-top: 20px;
  }

  &__paragraph {
    opacity: 0.6;
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    margin-bottom: 24px;
    margin-top: 24px;
  }
}
