.bullet-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  &__text {
    display: inline-flex;
    flex: 1 0 0;
    color: $white;
    opacity: 1;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    width: 100%;
  }

  &__index {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 13px;
    width: 32px;
    height: 32px;
    background-color: $white;
    box-shadow: 0 1px 3px 0 rgba(47, 57, 71, 0.05);
    font-size: 12px;
    color: #2f3947;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
  }
}
