.comments-section-wrapper {
  position: relative;
  min-height: 429px;
  max-width: 1200px;
  margin: 0 auto;
}

.comments-section {
  &__top {
    text-align: center;
    padding-bottom: 22px;
    padding-top: 40px;
    border-top: 1px solid rgba(128, 151, 177, 0.15);

    @media #{$col-md} {
      padding-top: 80px;
      padding-bottom: 70px;
    }
  }

  &__tag {
    font-size: 11px;
    color: $black;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 36px;
  }
}

.tns-controls {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  @media #{$col-md} {
    margin-bottom: 120px;
  }

  button {
    background-color: $black;
    border: none;
    border-radius: 50%;
    width: 44px;
    height: 44px;
  }

  button:first-child {
    margin-right: 10px;
    background-image: url('/assets/images/arrow-left.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-position: 14px 19px;
    color: transparent;
  }

  button:last-child {
    margin-left: 10px;
    background-image: url('/assets/images/arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-position: 8px 19px;
    color: transparent;
  }
}
