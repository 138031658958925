.job-demand-section-blue-bg {
  width: 100%;
  height: 380px;
  margin-bottom: -280px;

  &--facebook {
    background-color: $blue;
  }

  &--tableau {
    background-color: $blue2;
  }

  &--unity {
    background-color: $black;
  }
}

.job-demand-section {
  text-align: center;
  max-width: 1200px;
  background: $white;
  border: 1px solid rgba(47, 57, 71, 0.05);
  border-radius: 6px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 50px 40px 40px 40px;
  position: relative;

  @media #{$col-md} {
    margin: 0 auto;
    padding: 80px 80px 60px 80px;
  }

  &__chart {
    padding-top: 62px;

    &--facebook {
      #tableauChart {
        display: none !important;
      }

      #unityChart {
        display: none !important;
      }
    }

    &--tableau {
      #facebookChart {
        display: none !important;
      }

      #unityChart {
        display: none !important;
      }
    }

    &--unity {
      #facebookChart {
        display: none !important;
      }

      #tableauChart {
        display: none !important;
      }
    }
  }

  &__heading {
    font-family: $font-proximaNova;
    font-size: 26px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    line-height: 40px;
  }

  &__description {
    padding-top: 14px;
    opacity: 0.6;
    font-family: $font-proximaNova;
    font-size: 15px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    line-height: 26px;
  }
}
