$black: #000;
$white: #fff;
$blue: #126ee6;
$blue2: #457387;
$gray: #000;
$gray-light: #f4f5f6;
$gray-light2: #fafbfd;

// components
$background-color: $gray-light2;
