form {
  label:not(.checkbox-label) {
    display: none;
  }

  .input,
  input:not([type='checkbox']) {
    margin-bottom: 20px;
    font-family: $font-proximaNova;
    color: $black;
    letter-spacing: 0;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    border: none;
    box-sizing: border-box;
    background: $gray-light;
    border-radius: 4px;
    font-size: 14px;
    line-height: 26px;

    &:last-child {
      margin-bottom: 0;
    }

    &--disabled {
      background-color: #fbfbfb;
      cursor: not-allowed;
    }

    &--medium {
      width: 200%;
    }

    &:focus {
      outline: none;
      border-color: 1px solid rgba(47, 57, 71, 0.1);
    }

    &::placeholder {
      color: #666;
    }
  }

  .fields-wrapper {
    display: flex;

    input:first-of-type {
      margin-right: 20px;
    }
  }
}
