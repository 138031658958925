.heading {
  color: $white;
  font-size: 32px;
  letter-spacing: -0.27px;
  line-height: 44px;
  margin-bottom: 18px;

  &--size-medium {
    font-size: 26px;
    letter-spacing: 0;
    line-height: 40px;
  }

  &--color-black {
    color: $black;
  }
}
