html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: $font-default;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

svg {
  pointer-events: none;
}

strong {
  font-weight: bold;
}

u {
  text-decoration: underline;
}

em {
  font-style: italic;
}

s {
  text-decoration: line-through;
}

a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
