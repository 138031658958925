.activity-section {
  max-width: 791px;
  margin: 0 auto;
  padding-bottom: 40px;

  @media #{$col-md} {
    padding-bottom: 100px;
  }

  &__timeline {
    width: 100%;
    position: relative;

    &::after {
      content: "";
      clear: both;
      display: table;
      width: 100%;
    }

    & .timeline-item--blue {
      @media #{$col-md} {
        &::before {
          background: $blue;
        }

        &::after {
          background: $blue;
        }
      }
    }

    & .timeline-item--black {
      @media #{$col-md} {
        &::before {
          background: $black;
        }

        &::after {
          background: $black;
        }
      }
    }

    & .timeline-item--blue2 {
      @media #{$col-md} {
        &::before {
          background: $blue2;
        }

        &::after {
          background: $blue2;
        }
      }
    }

    & .timeline-item {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      @media #{$col-md} {
        &::before {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          // background: $blue;
          z-index: 1;
          border-radius: 100%;
        }

        &::after {
          content: '';
          position: absolute;
          width: 2px;
          // background: $blue;
          height: 100%;
        }

        &.first {
          &::after {
            top: 50%;
            height: 50%;
          }
        }

        &.last {
          &::after {
            top: 0;
            height: 50%;
          }
        }

        &-left {
          justify-content: flex-start;

          &::after {
            right: calc(50% - 2px);
          }

          &::before {
            right: calc(50% - 9px);
          }
        }

        &-right {
          justify-content: flex-end;

          &::after {
            left: 50%;
          }

          &::before {
            left: calc(50% - 7px);
          }
        }
      }

      & .content {
        position: relative;
        width: 100%;
        background: $white;
        border: 1px solid rgba(47, 57, 71, 0.05);
        border-radius: 6px;
        padding: 24px 32px 22px 32px;
        margin: 10px 20px;

        @media #{$col-md} {
          margin: 0;
          width: 336px;
        }

        &__heading {
          font-family: $font-poppins;
          font-weight: 600;
          font-size: 16px;
          color: #2f3947;
          letter-spacing: -0.18px;
          line-height: 26px;
          margin-bottom: 12px;
        }

        &__description {
          opacity: 0.6;
          font-family: $font-poppins;
          font-weight: 600;
          font-size: 13px;
          color: $black;
          letter-spacing: 0;
          line-height: 26px;
        }
      }
    }
  }
}
