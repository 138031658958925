.contact-form {
  margin-top: 20px;
  background: $white;
  border: 1px solid rgba(47, 57, 71, 0.05);
  border-radius: 6px;
  padding: 50px 40px 40px 40px;

  @media #{$col-md} {
    margin: 0;
  }

  &__privacy-info {
    opacity: 0.6;
    font-size: 12px;
    color: $black;
    letter-spacing: 0;
    text-align: center;
    margin-top: 20px;
    line-height: 18px;
  }
}
