.grid-one {
  width: 100%;
  height: 100%;
  max-width: $column-width-default;
}

@media #{$col-sm} {
  .grid-one-sm {
    width: 100%;
    height: 100%;
    max-width: $column-width-default;

    @media #{$col-lg} {
      max-width: $column-width-lg;
    }

    @media #{$col-xl} {
      max-width: $column-width-xl;
    }
  }
}

.grid-two {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 1);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 1);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 1);
  }
}

@media #{$col-sm} {
  .grid-two-sm {
    width: 100%;
    height: 100%;
    max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 1);

    @media #{$col-lg} {
      max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 1);
    }

    @media #{$col-xl} {
      max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 1);
    }
  }
}

.grid-three {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 2);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 2);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 2);
  }
}

@media #{$col-sm} {
  .grid-three-sm {
    width: 100%;
    height: 100%;
    max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 2);

    @media #{$col-lg} {
      max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 2);
    }

    @media #{$col-xl} {
      max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 2);
    }
  }
}

.grid-four {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 3);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 3);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 3);
  }
}

.grid-five {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 4);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 4);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 4);
  }
}

.grid-six {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 5);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 5);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 5);
  }
}

@media #{$col-sm} {
  .grid-six-sm {
    width: 100%;
    height: 100%;
    max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 5);

    @media #{$col-lg} {
      max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 5);
    }

    @media #{$col-xl} {
      max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 5);
    }
  }
}

.grid-seven {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 6);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 6);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 6);
  }
}

@media #{$col-sm} {
  .grid-seven-sm {
    width: 100%;
    height: 100%;
    max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 6);

    @media #{$col-lg} {
      max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 6);
    }

    @media #{$col-xl} {
      max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 6);
    }
  }
}

.grid-eight {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 7);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 7);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 7);
  }
}

.grid-nine {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 8);
  margin: 0 auto;

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 8);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 8);
  }
}

.grid-ten {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 9);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 9);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 9);
  }
}

.grid-eleven {
  width: 100%;
  height: 100%;
  max-width: $column-width-default + (($column-width-default + $grid-gutter-default) * 10);

  @media #{$col-lg} {
    max-width: $column-width-lg + (($column-width-lg + $grid-gutter-lg) * 10);
  }

  @media #{$col-xl} {
    max-width: $column-width-xl + (($column-width-xl + $grid-gutter-xl) * 10);
  }
}

.grid-twelve {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.hidden-xs {
  display: none;

  @media #{$col-sm} {
    display: block;
  }
}

.horizontal-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.vertical-center {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.horizontal-flex-end {
  display: flex;
  justify-content: flex-end;
}

.horizontal-space-between {
  display: flex;
  justify-content: space-between;
}

.horizontal-end {
  display: flex;
  justify-content: flex-end;
}

.clear {
  padding: 0 !important;
  margin: 0 !important;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}
